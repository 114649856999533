<template>
  <div class="d-flex justify-content-center align-items-center w-100 h-100">
    <div>
      <p class="mb-4">{{ message }}</p>
      <p class="text-center">
        <button
          type="button"
          @click="$emit('retry')"
          class="btn btn-action py-1 px-4"
        >
          {{ $t('common.retry') }}
        </button>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'retry',

  props: {
    message: {
      type: String,
      default: null,
    },
  },
};
</script>

<style scoped></style>
