<template>
  <div class="box">
    <div class="box-header">
      ข้อมูลทั่วไปจังหวัด
      <button
        v-if="closeButton"
        @click="$emit('closeProvinceGeneral')"
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
      >
        <eva-icon name="close-outline"></eva-icon>
      </button>
    </div>
    <div class="box-body" v-if="provinceGeneral">
      <div class="d-flex justify-content-between mt-2">
        <div>พื้นที่</div>
        <div class="helper-color">
          {{ provinceGeneral.area | auto_number(' ตร.กม') }}
        </div>
      </div>
      <div class="d-flex justify-content-between mt-2">
        <div>จํานวนประชากร</div>
        <div class="helper-color">
          {{ provinceGeneral.population | auto_number(' คน') }}
        </div>
      </div>
      <div class="d-flex justify-content-between mt-2">
        <div>GPP</div>
        <div class="helper-color">
          {{ provinceGeneral.gpp | auto_number(' บาท') }}
        </div>
      </div>
      <div class="d-flex justify-content-between mt-2">
        <div>จํานวนครัวเรือนในจังหวัด</div>
        <div class="helper-color">
          {{ (provinceGeneral.household * 1000.0) | auto_number(' ครัวเรือน') }}
        </div>
      </div>
      <div class="d-flex justify-content-between mt-2">
        <div>จํานวนครัวเรือนที่ไม่มีไฟฟ้าใช้</div>
        <div class="helper-color">
          {{
            (provinceGeneral.householdNoEl * 1000.0) | auto_number(' ครัวเรือน')
          }}
        </div>
      </div>
      <div class="d-flex justify-content-between mt-2">
        <div>ปริมาณการใช้พลังงานทั้งหมด</div>
        <div class="helper-color">
          {{ provinceGeneral.finalEnergyCons | auto_number(' ktoe') }}
        </div>
      </div>
      <div class="box-footer mt-4 pt-3">
        <p class="source">
          ที่มา:
          {{
            provinceGeneral.sources ? provinceGeneral.sources.join(', ') : ''
          }}
        </p>
      </div>
    </div>
    <loading-retry
      v-if="!provinceGeneral"
      :fetchPromise="init"
      retryMessage="ไม่สามารถแสดงข้อมูลจังหวัดได้ในขณะนี้"
    ></loading-retry>
  </div>
</template>

<script>
import LoadingRetry from '@/components/LoadingRetry.vue';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

export default {
  name: 'ProvinceGeneral',

  components: {
    LoadingRetry,
  },

  props: {
    nationalPlan: {
      type: Object,
      default: null,
    },
    province: {
      type: Object,
      default: null,
    },
    closeButton: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      provinceGeneral: null,
    };
  },

  methods: {
    ...mapActions('mondas', ['fetchProvinceGeneral']),
    init() {
      return this.fetchProvinceGeneral({
        year: this.$options.filters.thai_year(this.nationalPlan.year),
        province: this.province,
      }).then((data) => {
        this.provinceGeneral = data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.loading-retry {
  min-height: 150px;
}
.source {
  color: #5d5d5d;
  text-align: center;
  font-size: 0.8rem;
}
</style>
