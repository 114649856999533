<template>
  <div class="">
    <chart-box
      chartType="pie"
      :identifier="firstIdentifier"
      title="การใช้พลังงานในปีล่าสุด ตามประเภทพลังงาน (ktoe)"
      :fetchPromise="fetchByEnergy"
      class="mb-4"
    >
    </chart-box>
    <chart-box
      chartType="pie"
      :identifier="secondIdentifier"
      title="การใช้พลังงานในปีล่าสุด ตามสาขา (ktoe)"
      :fetchPromise="fetchBySector"
    >
    </chart-box>
  </div>
</template>

<script>
import Vue from 'vue';
import ChartBox from '@/components/ChartBox.vue';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import {
  get,
  head,
  isEmpty,
  values,
  forIn,
  range,
  keys,
  sum,
  reduce,
  add,
  concat,
  uniq,
  map,
  flatMap,
  compact,
} from 'lodash';

export default {
  name: 'EnergyConsumeChart',

  components: {
    ChartBox,
  },

  props: {
    firstIdentifier: {
      type: String,
      default: null,
    },
    secondIdentifier: {
      type: String,
      default: null,
    },
    province: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      baseUrl: process.env.VUE_APP_BACKEND_URL,
    };
  },

  created() {},

  computed: {
    ...mapGetters(['latestNationalPlan']),
    currentYear() {
      return this.$options.filters.thai_year(this.latestNationalPlan.year);
    },
  },

  methods: {
    ...mapActions('mondas', [
      'fetchPreEnergyConsume',
      'fetchEnergyConsumeByYear',
    ]),
    fetchByEnergy() {
      const energyFields = {
        lpg: {
          title: 'แอลพีจี',
          data: [
            'RDS_LPGConsRes',
            'RDS_LPGConsInd',
            'RDS_LPGConsCom',
            'RDS_LPGConsTrans',
            'RDS_LPGConsOther',
          ],
        },
        lsd: {
          title: 'ดีเซลหมุนช้า',
          data: [
            'RDS_LSDConsRes',
            'RDS_LSDConsAgr',
            'RDS_LSDConsInd',
            'RDS_LSDConsCom',
            'RDS_LSDConsTrans',
            'RDS_LSDConsOther',
          ],
        },
        hsd: {
          title: 'ดีเซลหมุนเร็ว/ไบโอดีเซล',
          data: [
            'RDS_HSDConsRes',
            'RDS_HSDConsAgr',
            'RDS_HSDConsInd',
            'RDS_HSDConsCom',
            'RDS_HSDConsTrans',
            'RDS_HSDConsOther',
          ],
        },
        fuelOil: {
          title: 'น้ำมันเตา',
          data: [
            'RDS_OilConsAgr',
            'RDS_OilConsInd',
            'RDS_OilConsCom',
            'RDS_OilConsTrans',
            'RDS_OilConsOther',
          ],
        },
        gasoline91: {
          title: 'แก๊สโซลีน 91',
          data: [
            'RDS_Gasl91ConsRes',
            'RDS_Gasl91ConsAgr',
            'RDS_Gasl91ConsInd',
            'RDS_Gasl91ConsCom',
            'RDS_Gasl91ConsTrans',
            'RDS_Gasl91ConsOther',
          ],
        },
        gasoline95: {
          title: 'แก๊สโซลีน 95',
          data: [
            'RDS_Gasl95ConsRes',
            'RDS_Gasl95ConsAgr',
            'RDS_Gasl95ConsInd',
            'RDS_Gasl95ConsCom',
            'RDS_Gasl95ConsTrans',
            'RDS_Gasl95ConsOther',
          ],
        },
        gasohol91: {
          title: 'แก๊สโซฮอล์ 91',
          data: [
            'RDS_Gash91ConsRes',
            'RDS_Gash91ConsAgr',
            'RDS_Gash91ConsInd',
            'RDS_Gash91ConsCom',
            'RDS_Gash91ConsTrans',
            'RDS_Gash91ConsOther',
          ],
        },
        gasohol95: {
          title: 'แก๊สโซฮอล์ 95',
          data: [
            'RDS_Gash95ConsRes',
            'RDS_Gash95ConsAgr',
            'RDS_Gash95ConsInd',
            'RDS_Gash95ConsCom',
            'RDS_Gash95ConsTrans',
            'RDS_Gash95ConsOther',
          ],
        },
        naturalGas: {
          title: 'ก๊าซธรรมชาติ',
          data: [
            'RDS_NGasConsInd',
            'RDS_NGasConsCom',
            'RDS_NGasConsTrans',
            'RDS_NGasConsOther',
          ],
        },
        electricity: {
          title: 'ไฟฟ้า',
          data: [
            'RDS_ElConsRes',
            'RDS_ElConsAgr',
            'RDS_ElConsInd',
            'RDS_ElConsCom',
            'RDS_ElConsTrans',
            'RDS_ElConsOther',
          ],
        },
      };
      return this.fetchEnergyLastYear(energyFields).then((data) => {
        return {
          title: 'ปริมาณการใช้พลังงาน ตามประเภทพลังงาน ในปี ' + data.year,
          unit: 'ktoe',
          data: [['ประเภทพลังงาน', 'ktoe'], ...data.values],
          sources: data.sources,
        };
      });
    },
    fetchBySector() {
      const energyFields = {
        residential: {
          title: 'สาขาบ้านอยู่อาศัย',
          data: [
            'RDS_LPGConsRes',
            'RDS_LSDConsRes',
            'RDS_HSDConsRes',
            'RDS_Gasl91ConsRes',
            'RDS_Gasl95ConsRes',
            'RDS_Gash91ConsRes',
            'RDS_Gash95ConsRes',
            'RDS_ElConsRes',
          ],
        },
        agricultural: {
          title: 'สาขาเกษตรกรรม',
          data: [
            'RDS_LSDConsAgr',
            'RDS_HSDConsAgr',
            'RDS_OilConsAgr',
            'RDS_Gasl91ConsAgr',
            'RDS_Gasl95ConsAgr',
            'RDS_Gash91ConsAgr',
            'RDS_Gash95ConsAgr',
            'RDS_ElConsRes',
          ],
        },
        industrial: {
          title: 'สาขาอุตสาหกรรม',
          data: [
            'RDS_LPGConsInd',
            'RDS_LSDConsInd',
            'RDS_HSDConsInd',
            'RDS_OilConsInd',
            'RDS_Gasl91ConsInd',
            'RDS_Gasl95ConsInd',
            'RDS_Gash91ConsInd',
            'RDS_Gash95ConsInd',
            'RDS_NGasConsInd',
            'RDS_ElConsInd',
          ],
        },
        commercial: {
          title: 'สาขาธุรกิจการค้า',
          data: [
            'RDS_LPGConsCom',
            'RDS_LSDConsCom',
            'RDS_HSDConsCom',
            'RDS_OilConsCom',
            'RDS_Gasl91ConsCom',
            'RDS_Gasl95ConsCom',
            'RDS_Gash91ConsCom',
            'RDS_Gash95ConsCom',
            'RDS_NGasConsCom',
            'RDS_ElConsCom',
          ],
        },
        transportation: {
          title: 'สาขาการขนส่ง',
          data: [
            'RDS_LPGConsTrans',
            'RDS_LSDConsTrans',
            'RDS_HSDConsTrans',
            'RDS_OilConsTrans',
            'RDS_Gasl91ConsTrans',
            'RDS_Gasl95ConsTrans',
            'RDS_Gash91ConsTrans',
            'RDS_Gash95ConsTrans',
            'RDS_NGasConsTrans',
            'RDS_ElConsTrans',
          ],
        },
        other: {
          title: 'สาขาอื่นๆ',
          data: [
            'RDS_LPGConsOther',
            'RDS_LSDConsOther',
            'RDS_HSDConsOther',
            'RDS_OilConsOther',
            'RDS_Gasl91ConsOther',
            'RDS_Gasl95ConsOther',
            'RDS_Gash91ConsOther',
            'RDS_Gash95ConsOther',
            'RDS_NGasConsOther',
            'RDS_ElConsOther',
          ],
        },
      };
      return this.fetchEnergyLastYear(energyFields).then((data) => {
        return {
          title: 'ปริมาณการใช้พลังงาน ตามสาขา ในปี ' + data.year,
          unit: 'ktoe',
          data: [['สาขา', 'ktoe'], ...data.values],
          sources: data.sources,
        };
      });
    },
    fetchEnergyLastYear(energyFields) {
      return this.fetchPreEnergyConsume({
        province: this.province,
        key: head(head(values(energyFields)).data),
      }).then((data) => {
        if (data) {
          const generalData = data instanceof Array ? head(data) : data;
          const latestYear = this.getTargetYear(generalData);
          const promises = [];
          forIn(energyFields, (item, field) => {
            promises.push(
              this.fetchEnergyConsumeByYear({
                year: latestYear,
                province: this.province,
                keys: item.data,
              }).then((data) => {
                data = concat([], data);
                let sources = uniq(map(data, 'source')) || 'ไม่มีข้อมูล';
                const sum = reduce(
                  data,
                  (sum, object) => {
                    return add(sum, object.value);
                  },
                  0
                );
                return {
                  sources: sources,
                  values: [item.title, sum],
                };
              })
            );
          });
          return Promise.all(promises).then((list) => {
            return {
              year: latestYear,
              sources: compact(uniq(flatMap(list, 'sources'))),
              values: map(list, 'values'),
            };
          });
        }
      });
    },
    getTargetYear(generalData) {
      let currentYearInt = parseInt(this.currentYear);
      let targetYear = currentYearInt;
      if (generalData) {
        let minYear = generalData.min_year;
        let maxYear = generalData.max_year;
        if (currentYearInt < minYear) {
          targetYear = minYear;
        } else if (currentYearInt > maxYear) {
          targetYear = maxYear;
        }
      }
      return targetYear;
    },
    json(params) {
      return 'data=' + encodeURIComponent(JSON.stringify(params));
    },
  },
};
</script>

<style scoped></style>
