<template>
  <div
    class="
      loading-retry
      d-flex
      justify-content-center
      align-items-center
      w-100
      h-100
    "
    v-if="!ready"
  >
    <vue-element-loading
      :active="!ready && !error"
      spinner="spinner"
      color="#ED110E"
      class="position-relative"
      background-color="transparent"
    />
    <retry
      class="position-absolute"
      v-on:retry="fetch"
      v-if="!ready && error"
      :message="retryMessage"
    ></retry>
  </div>
</template>

<script>
import Retry from '@/components/Retry.vue';

export default {
  name: 'LoadingRetry',

  components: {
    Retry,
  },

  props: {
    fetchPromise: {
      type: Function,
      required: true,
    },
    retryMessage: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      ready: false,
      error: false,
    };
  },

  mounted() {
    this.fetch();
  },

  methods: {
    fetch() {
      this.ready = false;
      this.error = false;
      this.fetchPromise()
        .then(() => {
          this.ready = true;
          this.error = false;
        })
        .catch(() => {
          this.ready = false;
          this.error = true;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.loading-retry {
  min-height: 150px;
}
</style>
