<template>
  <section class="province-summary-chart">
    <div class="row" v-if="ready">
      <div class="col-12 py-2">
        <energy-consume-chart :province="province" />
      </div>
      <div class="col-12 py-2">
        <div class="box">
          <div class="box-header">
            <div class="d-flex align-items-center justify-content-between">
              <div class="ml-3 font-weight-bold">โรงไฟฟ้าแยกรายเชื้อเพลิง</div>
              <collapse-button
                v-model="showPowerPlantTableData"
              ></collapse-button>
            </div>
          </div>
          <div class="box-body" v-show="showPowerPlantTableData">
            <div class="">
              <div class="table-responsive">
                <table class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th scope="col">ประเภทเชื้อเพลิง</th>
                      <th scope="col">จำนวน (แห่ง)</th>
                      <th scope="col">กำลังผลิตติดตั้ง (MW)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(row, index) in powerPlantTableData"
                      v-bind:key="index"
                    >
                      <td v-for="(item, index) in row" v-bind:key="index">
                        {{ index == 0 ? $t(item.trim()) : item }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 py-2">
        <existing-supply-chart :province="province" />
      </div>
      <div class="col-12 py-2">
        <chart-box
          chartType="pie"
          title="ปริมาณการใช้พลังงานไฟฟ้า รายสาขา"
          :fetchPromise="fetchElectricConsume"
        />
      </div>
    </div>
    <div class="row" v-else>
      <div class="col-12 py-2">
        <div class="box">
          <div class="box-header">
            <div>กราฟการใช้พลังงานของจังหวัด</div>
          </div>
          <div class="box-body"></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import LoadingRetry from '@/components/LoadingRetry.vue';
import ExistingSupplyChart from '@/components/ExistingSupplyChart.vue';
import ChartBox from '@/components/ChartBox.vue';
import EnergyConsumeChart from '@/components/EnergyConsumeChart.vue';
import { get, head, groupBy, reduce, forIn } from 'lodash';
import CollapseButton from '@/components/CollapseButton.vue';

export default {
  name: 'ProvinceSummaryChart',

  components: {
    ExistingSupplyChart,
    ChartBox,
    EnergyConsumeChart,
    CollapseButton,
  },

  props: {
    nationalPlan: {
      type: Object,
      default: null,
    },
    province: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      ready: true,
      chartData: {
        renewableEnergy: [],
      },
      powerPlantTableData: [],
      showPowerPlantTableData: false,
      chartOption: {
        renewableEnergy: {
          chartArea: { width: '50%' },
          height: 350,
          chart: {},
          pieHole: 0.7,
          legend: { position: 'right' },
          pieSliceText: 'label',
        },
      },
      // chartData: [
      //   ['Year', 'Sales', 'Expenses', 'Profit'],
      //   ['2014', 1000, 400, 200],
      //   ['2015', 1170, 460, 250],
      //   ['2016', 660, 1120, 300],
      //   ['2017', 1030, 540, 350]
      // ],
      // chartOptions: {
      //   title: 'Company Performance',
      //   chartArea: {width: '50%'},
      //   height: 400,
      //   chart: {
      //     title: 'Company Performance',
      //     subtitle: 'Sales, Expenses, and Profit: 2014-2017',
      //   },
      //   bars: 'horizontal'
      // },
    };
  },

  created() {
    this.fetchPowerPlantTable();
  },

  computed: {
    currentYear() {
      return this.$options.filters.thai_year(this.nationalPlan.year);
    },
  },

  methods: {
    ...mapActions('mondas', [
      'fetchProvinceRenewableEnergy',
      'fetchPowerPlant',
      'fetchPreREEEPotential',
      'fetchREEEPotential',
      'fetchPreElectricConsumeBySector',
      'fetchElectricConsumeBySector',
      'fetchPreEnergyConsume',
      'fetchEnergyConsumeByYear',
    ]),
    ...mapActions('mondas', []),
    init() {
      if (!this.nationalPlan && !this.province) {
        return;
      }
      this.ready = true;
      // return this.fetchProvinceRenewableEnergy({
      //   year: this.$options.filters.thai_year(this.nationalPlan.year),
      //   province: this.province
      // }).then(data => {
      //   this.chartData.renewableEnergy = [
      //     ['EnergyType', 'ktoe'],
      //     ['พลังงานแสงอาทิตย์ติดตั้งบนหลังคา', data.solarRooftop],
      //     ['ไบโอดีเซล', data.biodiesel],
      //     ['เอทานอล', data.ethanol],
      //     ['ชีวมวล', data.biomass],
      //     ['ขยะชุมชน', data.msw],
      //     ['กีาซชีวภาพ', data.biogas]
      //   ];
      // this.ready = true;
      // });
    },
    fetchPowerPlantTable() {
      return this.fetchPowerPlant(this.province).then((data) => {
        const result = groupBy(data, (item) => {
          const powerPlant = item.data;
          return powerPlant.fuel;
        });
        this.powerPlantTableData = [];
        forIn(result, (item, fuel) => {
          const sum = reduce(
            item,
            (sum, n) => {
              return sum + n.data.value;
            },
            0
          );
          this.powerPlantTableData.push([
            fuel,
            item.length,
            this.$options.filters.auto_number(sum),
          ]);
        });
      });
    },
    fetchExistingSupplyOnlyRemain() {
      return this.fetchPreREEEPotential({
        province: this.province,
        key: 'RDREEEP_PVroofPotExRem',
      }).then((data) => {
        if (data) {
          const generalData = data instanceof Array ? head(data) : data;
          const targetYear = this.getTargetYear(generalData);
          return this.fetchREEEPotential({
            year: targetYear,
            province: this.province,
          }).then((data) => {
            const title = '';
            const result = [[title, 'ktoe']];
            result.push([
              'แสงอาทิตย์ติดตั้งบนหลังคา',
              get(data, 'RDREEEP_PVroofPotExRem.value', 0),
            ]);
            result.push([
              'ก๊าซชีวภาพ',
              get(data, 'RDREEEP_BiogasPotExRem.value', 0),
            ]);
            result.push(['ขยะ', get(data, 'RDREEEP_WastePotExRem.value', 0)]);
            result.push([
              'ชีวมวล',
              get(data, 'RDREEEP_BiomassPotExRem.value', 0),
            ]);
            result.push(['เอทานอล', get(data, 'RDREEEP_EthPotExRem.value', 0)]);
            result.push([
              'ไบโอดีเซล',
              get(data, 'RDREEEP_BiodieselPotExRem.value', 0),
            ]);
            const chartData = {
              title: 'ศักยภาพพลังงานทดแทนคงเหลือ (ktoe) ปี ' + targetYear,
              unit: generalData.unit,
              data: result,
            };
            return chartData;
          });
        }
      });
    },
    fetchElectricConsume() {
      return this.fetchPreElectricConsumeBySector({
        province: this.province,
        key: 'RDS_ElConsRes',
      }).then((data) => {
        if (data) {
          const generalData = data instanceof Array ? head(data) : data;
          const targetYear = this.getTargetYear(generalData);
          return this.fetchElectricConsumeBySector({
            year: targetYear,
            province: this.province,
          }).then((data) => {
            const unit = this.$config.prettyUnit(
              get(data, 'RDS_ElConsRes.unit', 'Not specified')
            );
            const title = 'สาขา';
            const result = [[title, `ปริมาณการใช้ไฟฟ้า ${unit}`]];
            result.push(['ครัวเรือน', get(data, 'RDS_ElConsRes.value', 0)]);
            result.push(['เกษตรกรรม', get(data, 'RDS_ElConsAgr.value', 0)]);
            result.push(['อุตสาหกรรม', get(data, 'RDS_ElConsInd.value', 0)]);
            result.push(['ธุรกิจ', get(data, 'RDS_ElConsCom.value', 0)]);
            result.push(['ขนส่ง', get(data, 'RDS_ElConsTrans.value', 0)]);
            result.push(['สาขาอื่นๆ', get(data, 'RDS_ElConsOther.value', 0)]);
            const chartData = {
              title: `การใช้พลังงานเชิงพาณิชย์ (${unit}) ปี ` + targetYear,
              unit: unit,
              data: result,
            };
            return chartData;
          });
        }
      });
    },
    fetchEnergyConsumeOfCommercialLastYear(energyFields) {
      return this.fetchPreEnergyConsume({
        province: this.province,
        key: 'RDS_LPGConsCom',
      }).then((data) => {
        if (data) {
          const generalData = data instanceof Array ? head(data) : data;
          const latestYear = this.getTargetYear(generalData);
          return this.fetchEnergyConsumeByYear({
            year: latestYear,
            province: this.province,
            isKeyHash: true,
            keys: [
              'RDS_LPGConsCom',
              'RDS_LSDConsCom',
              'RDS_HSDConsCom',
              'RDS_OilConsCom',
              'RDS_Gasl91ConsCom',
              'RDS_Gasl95ConsCom',
              'RDS_Gash91ConsCom',
              'RDS_Gash95ConsCom',
              'RDS_NGasConsCom',
              'RDS_ElConsCom',
            ],
          }).then((data) => {
            const title = 'ประเภทพลังงาน';
            const result = [[title, 'ktoe']];
            result.push(['LPG', get(data, 'RDS_LPGConsCom.value', 0)]);
            result.push([
              'Low Speed Diesel',
              get(data, 'RDS_LSDConsCom.value', 0),
            ]);
            result.push(['Biodiesel', get(data, 'RDS_HSDConsCom.value', 0)]);
            result.push(['Fuel oil', get(data, 'RDS_OilConsCom.value', 0)]);
            result.push([
              'Gasoline 91',
              get(data, 'RDS_Gasl91ConsCom.value', 0),
            ]);
            result.push([
              'Gasoline 95',
              get(data, 'RDS_Gasl95ConsCom.value', 0),
            ]);
            result.push([
              'Gasohol 91',
              get(data, 'RDS_Gash91ConsCom.value', 0),
            ]);
            result.push([
              'Gasohol 95',
              get(data, 'RDS_Gash95ConsCom.value', 0),
            ]);
            result.push(['Natural gas', get(data, 'RDS_NGasConsCom.value', 0)]);
            result.push(['Electricity', get(data, 'RDS_ElConsCom.value', 0)]);
            const chartData = {
              title: 'ปริมาณการใช้พลังงานเชิงพาณิชย์  ปี ' + latestYear,
              unit: generalData.unit,
              data: result,
              bars: 'vertical',
            };
            return chartData;
          });
        }
      });
    },
    getTargetYear(generalData) {
      let currentYearInt = parseInt(this.currentYear);
      let targetYear = currentYearInt;
      if (generalData) {
        let minYear = generalData.min_year;
        let maxYear = generalData.max_year;
        if (currentYearInt < minYear) {
          targetYear = minYear;
        } else if (currentYearInt > maxYear) {
          targetYear = maxYear;
        }
      }
      return targetYear;
    },
  },
};
</script>

<style lang="scss"></style>
