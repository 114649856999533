<template>
  <div>
    <chart-box
      chartType="bar"
      title="ศักยภาพลังงานทดแทน กลุ่ม Existing Supply (ktoe)"
      :identifier="identifier"
      :fetchPromise="fetchExistingSupply"
      tooltip="ศักยภาพพลังงานทดแทน กลุ่ม Existing Supply คือ ส่วนที่จัดเป็นวัตถุดิบพลังงานทดแทนที่มีอยู่ทั่วไปในปัจจุบัน และศักยภาพทางธรรมชาติ ที่เมื่อติดตั้งอุปกรณ์ผลิตพลังงานแล้วไม่ก่อให้เกิดการเปลี่ยนแปลงการใช้ประโยชน์ที่ดินในปัจจุบัน"
      helperText="หมายเหตุ:  กรณีที่ข้อมูลศักยภาพพลังงานติดลบ หมายถึง มีการใช้พลังงานมากกว่าศักยภาพพลังงานในจังหวัด"
    >
    </chart-box>
  </div>
</template>

<script>
import ChartBox from '@/components/ChartBox.vue';
import { get, head, toUpper, has } from 'lodash';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

export default {
  name: 'energy-capability',

  components: {
    ChartBox,
  },

  props: {
    identifier: {
      type: String,
      default: '',
    },
    province: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      baseUrl: process.env.VUE_APP_BACKEND_URL,
      existingSupply: {
        show: false,
      },
    };
  },

  created() {},

  computed: {
    ...mapGetters(['latestNationalPlan']),
    currentYear() {
      return this.$options.filters.thai_year(this.latestNationalPlan.year);
    },
  },

  methods: {
    ...mapActions('mondas', ['fetchPreREEEPotential', 'fetchREEEPotential']),
    fetchExistingSupply() {
      return this.fetchPreREEEPotential({
        province: this.province,
        key: 'RDREEEP_PVroofPotExTot',
      }).then((data) => {
        if (data) {
          const generalData = data instanceof Array ? head(data) : data;
          const targetYear = this.getTargetYear(generalData);
          return this.fetchREEEPotential({
            year: targetYear,
            province: this.province,
          }).then((data) => {
            const title = 'Existing Supply (ktoe) ปี ' + targetYear;
            const result = [
              ['เทคโนโลยีการผลิต', 'ศักยภาพที่แท้จริง', 'ศักยภาพคงเหลือ'],
            ];

            result.push([
              'แสงอาทิตย์ติดตั้งบนหลังคา',
              this.getDataValueInsensitive(data, 'RDREEEP_PVroofPotExTot', 0),
              this.getDataValueInsensitive(data, 'RDREEEP_PVroofPotExRem', 0),
            ]);
            result.push([
              'ก๊าซชีวภาพ',
              this.getDataValueInsensitive(data, 'RDREEEP_BiogasPotExTot', 0),
              this.getDataValueInsensitive(data, 'RDREEEP_BiogasPotExRem', 0),
            ]);
            result.push([
              'ขยะ',
              this.getDataValueInsensitive(data, 'RDREEEP_WastePotExTot', 0),
              this.getDataValueInsensitive(data, 'RDREEEP_WastePotExRem', 0),
            ]);
            result.push([
              'ชีวมวล',
              this.getDataValueInsensitive(data, 'RDREEEP_BiomassPotExTot', 0),
              this.getDataValueInsensitive(data, 'RDREEEP_BiomassPotExRem', 0),
            ]);
            result.push([
              'เอทานอล',
              this.getDataValueInsensitive(data, 'RDREEEP_EthPotExTot', 0),
              this.getDataValueInsensitive(data, 'RDREEEP_EthPotExRem', 0),
            ]);
            result.push([
              'ไบโอดีเซล',
              this.getDataValueInsensitive(
                data,
                'RDREEEP_BiodieselPotExTot',
                0
              ),
              this.getDataValueInsensitive(
                data,
                'RDREEEP_BiodieselPotExRem',
                0
              ),
            ]);

            const chartData = {
              title: title,
              unit: generalData.unit,
              data: result,
            };
            return chartData;
          });
        }
      });
    },
    getTargetYear(generalData) {
      let currentYearInt = parseInt(this.currentYear);
      let targetYear = currentYearInt;
      if (generalData) {
        let minYear = generalData.min_year;
        let maxYear = generalData.max_year;
        if (currentYearInt < minYear) {
          targetYear = minYear;
        } else if (currentYearInt > maxYear) {
          targetYear = maxYear;
        }
      }
      return targetYear;
    },
    json(params) {
      return 'data=' + encodeURIComponent(JSON.stringify(params));
    },
    getDataValueInsensitive(object, key, default_value = 0) {
      const upper = toUpper(key);
      if (has(object, key)) {
        return get(object, `${key}.value`, default_value);
      } else if (has(object, upper)) {
        return get(object, `${upper}.value`, default_value);
      }
      return default_value;
    },
  },
};
</script>
